export var engLangId=19;
export var cheLangId=18;
export var languageId=1;
export const channelId=1;
export const GoalsGraph={
    min:0,
    max:20
}

export const defaultH2HFilterId = 1;
export const staticEng ={
    match:"Match",
    tournament:"Tournament",
    headTOHeadSearch:"Head To Head Search",
    teamSearch:"Team Search",
    SearchByTeamName:"Search by team name",
    homeForm:"Home Form",
    awayForm:"Away Form",
    hadPredictor:"Win Predictor",
    bestBet:"Best Bet",
    best:"Best",
    bet:"Bet",
    date:"Date",
    mcDate:"Date",
    faq:"FAQ",
    contactUs:"Contact Us",
    responsibleGamblingPolicy:"Responsible Gambling Policy",
    privacy:"Privacy",
    disclaimerUs:"Disclaimer Us",
    securityTips:"Security Tips",
    copyRightText:"Copyright @ 2000-2020 The Hong Kong Jockey Club. All Rights Reserved.",
    rules:"Rules",
    footerText:"The entire service of FootyLogic is provided by Bettorlogic and it is intended to be used for reference only. Bettorlogic shall not be liable to any person for any loss or damage suffered by such person as a result of the use of the FootyLogic. Please browse the page using IE11.0 and above, Chrome, Firefox and Safari for the optimum viewing. Rankings referenced in these pages are according to FIFA World Rankings (for international teams), and Bettorlogic’s club rankings (for club cup tournaments).",
    headToHead:"Head To Head",
    headToHead8RE:"Head To Head (8 Recent Encounters)",
    mcH2H:"Head To Head",
    seasonalStats:"Seasonal Stats",
    playerAnalysis:"Player Analysis",
    preview:"Preview",
    odds:"Odds",
    basicMode:"Basic Mode",
    advancedMode:"Advanced Mode",
    summary:"Summary",
    recentForms:"Recent Form",
    statistics:"Statistics",
    hadHandicap:"HAD & Handicap",
    hiLoCorners:"HiLo & Corners",
    hiLoHandicap:"HiLo & Handicap",
    predictor:"Predictor",
    last8Encounters:"Last 8 Encounters",
    pastEncounters:"Past Encounters",
    season:"Season",
    comp:"Comp",
    competition:"Competition",
    homeTeam:"Home Team",
    pos:"Pos",
    score:"Score",
    ht:"HT",
    scoreHt:"Score (HT)",
    awayTeam:"Away Team",
    had:"HAD",
    fHAD:"FHAD",
    hdc:"HDC",
    hHAD:"HHAD",
    more:"More",
    home:"Home",
    away:"Away",
    draw:"Draw",
    won:"Won",
    lost:"Lost",
    homeAwayDraw:"Home/Away/Draw",
    firstHalfHAD:"First Half HAD",
    goalsPerGame:"Goals Per Game",
    per:"Per",
    game:"Game",
    handicapHAD:"Handicap HAD",
    fullTime:"Full Time",
    halfTime:"Half Time",
    haFu:"HaFu",
    handicap:"Handicap",
    handicaps:"Handicaps",
    correctScore:"Correct Score",
    csHafu:"CORRECT SCORE & HaFu",
    ttg:"TTG",
    fhHiLo:"FH HiLo",
    hiLo:"HiLo",
    btts:"Both Teams to Score",
    bts:"Both to Score",
    tts:"Teams To Score",
    cornersHiLo:"Corners HiLo",
    goal:"Goal",
    line: "Line",
    ftHiLo: "FT HiLo",
    htHiLo:"HT HiLo",
    cornersPerGame:"Corners Per Game",
    cornersForPerGame: "Corners For Per Game",
    cornersAgainstPerGame: "Corners Against Per Game",
    cornersFor: "Corners For",
    for:"for",
    cornersAgainst: "Corners Against",
    totalGoals:"Total Goals",
    totalGoalsAnalysis:"Total Goals Analysis",
    total:"Total",
    goalTimes:"Goal Times",
    firstToScore:"First To Score",
    corners:"Corners",
    recentResults:"8 Recent Results",
    event:"Event",
    ha:"H/A",
    against:"Against",
    ftScore:"FT Score",
    htScore:"HT Score",
    ftResult:"FT Result",
    result:"Result",
    win:"Win",
    loss:"Loss",
    all:"All",
    team:"Team",
    p:"P",
    w:"W",
    wTable:"W",
    d:"D",
    l:"L",
    h:"H",
    gf:"GF",
    ga:"GA",
    gd:"GD",
    pts:"Pts",
    form:"Form",
    highIcon:"H",
    lowIcon:"L",
    lossIcon:"L",
    hdcWinIcon:"W",
    hdcLossIcon:"L",
    hadWinIcon:"W",
    hadLossIcon:"L",
    a:"A",
    rank:"Rank",
    tournamentRank:"Rank",
    draws:"Draws",
    wins:"Wins",
    losses:"Losses",
    fullWin:"Full Win",
    halfWin:"Half Win",
    halfLoss:"Half Loss",
    fullLoss:"Full Loss",
    fullWins:"Full Wins",
    halfWins:"Half Wins",
    halfLosses:"Half Losses",
    fullLosses:"Full Losses",
    ww:"WW",
    dw:"DW",
    lw:"LW",
    wd:"WD",
    dd:"DD",
    ld:"LD",
    wl:"WL",
    wbyl:"W/L",
    fhwbyl:"FH W/L",
    dl:"DL",
    ll:"LL",
    avarage:"Average",
    plus:"+",
    minus: "-",
    zero:"0",
    hi:"Hi",
    lo:"Lo",
    teamScore:"Team Score",
    odd:"Odd",
    even:"Even",
    scored1st:"Scored 1st",
    conceded1st:"Conceded 1st",
    scoredFirst:"Scored First",
    concededFirst:"Conceded First",
    scoredLast:"Scored Last",
    concededLast:"Conceded Last",
    seasonRankingTrend:"Season Ranking Trend",
    hdcTypeComparison:"HDC Type Comparison",
    handicapHADComparison:"Handicap HAD Comparison",
    hadComparison:"HAD Comparison",
    hafuComparison:"HaFu Comparison",
    ttsOEComparison:"Odd/Even Comparison",
    ffsComparison:"First to Score Comparison",
    correctScoreComparision:"Correct Score Comparison",
    cornersComparision:"Corners Comparison",
    totalGoalsComparison:"Total Goals Comparison",
    temporarilyNoData:"Temporarily no information available",
    player:"Player",
    teamPlayer:"Player",
    started:"Started",
    missed:"Missed",
    goalsFor:"Goals For",
    with:"With",
    wo:"W/O",
    winPer:"Win%",
    drawPer:"Draw%",
    losePer:"Lose%",
    goalsAgainst:"Goals Against",
    league:"League",
    cup:"Cup",
    international:"International",
    standings:"Standings",
    fixturesResults:"Fixtures/Results",
    topScorer :"Top Scorer",
    players:"Players",
    club:"Club",
    nationality:"Nationality",
    goals:"Goals",
    hKJCNo:"TPS No.",
    squad:"Squad",
    position:"Position",
    age:"Age",
    appearances:"Appearances",
    assists:"Assists",
    generate:"Generate",
    type:"Type",
    country:"Country",
    continent:"Continent",
    h2hTitle:"Enter Team Names",
    last8HomeResults:"Last 8 Home Results",
    last8HomeResultsLFL:"Last 8 Home Results (Latest from left)",
    last8AwayResultsLFL:"Last 8 Away Results (Latest from left)",
    latestFromLeft:"Latest from left",
    played:"Played",
    recentEncounters:"Recent Encounters",
    firstHalfHiLo:"FIRST HALF HiLo",
    last8HomeResults:"Last 8 Home Results",
    last8AwayResults:"Last 8 Away Results",
    seasonSummary:"Season Summary",
    high:"High",
    low:"Low",
    goalScoringTimeAnalysis:"GOALSCORING TIME ANALYSIS",
    Recent8Homeleaguematches:"Recent 8 Home league matches",
    Recent8AwayLeagueMatches:"Recent 8 Away league matches",
    nextMatch:"Next Match",
    matchResult:"Match Result",
    past3Seasons:"Past 3 Seasons",
    point:"Points",
    wprc:"W%",
    both:"Both",
    ft:"FT",
    average:"Average",
    matches:"Matches",
    scoreFirst:"Score First",
    oppositScoreFirst:"Opposite Score First",
    leagueTable:"League Table",
    firstHalfCorrectScore:"FIRST HALF CORRECT SCORE",
    firstTeamToScore:"First Team to Score",
    cornerHiLo:"Corner HiLo",
    search:"Search",
    orselectbelow:"SELECT 2 TEAMS",
    yellowcards:"Yellow Cards",
    redcards:"Red Cards",
    subbedon:"Subbed On",
    subbedoff:"Subbed Off",
    firstHalf:"First Half",
    gpg:"GPG",
    enterteamname:"Enter team name",
    jan:"Jan",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "May",
    jun: "Jun",
    jul:"Jul",
    aug: "Aug",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
    dec: "Dec",
    january:'January',
    february:'February',
    march:'March',
    april:'April',
    may:'May',
    june:'June',
    july:'July',
    august:'August',
    september:'September',
    october:'October',
    november:'November',
    december:'December',
    sunday:'Sunday',
    monday:'Monday',
    tuesday:'Tuesday',
    wednesday:'Wednesday',
    thursday:'Thursday',
    friday:'Friday',
    saturday:'Saturday',
    nogoals:"No Goals",
    noupcominggames:'No upcoming games',
    koTime: "KO Time",
    homeWin: "Home Win",
    awayWin: "Away Win",
    count: "Count",
    stats: "Stats",
    oddOREven: "Odd/Even",
    fixtures: "Fixtures",
    results: "Results",
    goalLine: "Goal Line",
    htft: "Half Time/Full Time",
    lastUpdatedOn: "Last updated on",
    last16: "Round of 16",
    quarterFinals: "Quarter finals",
    semiFinals: "Semi finals",
    final: "Final",
    showAll: "Show All",
    showLess: "Show Less",
    noPastMeetings: "No past meetings",
    noPastMeetingsVenue:"No past meetings at venue",
    firstHalfGpg:"First Half GPG",
    scoreFirstAndWin:"Score First and Win",
    standingsl:"L",
    teams:"Teams",
    structure:"Structure",
    promotion:"Promotion",
    relegation:"Relegation",
    tieBreakRules:"Tie-Break Rules",
    penalties:"Penalties",
    team1:"Team 1",
    team2:"Team 2",
   firstLeg:"1st Leg",
   secondLeg:"2nd Leg",
   aggregate:"Agg.",
   group:"Group",
   winner:"Winner",
   runnerUp:"Runner-Up",
   corrections:"Corrections",
   points:"Points",
   replay:"Replay",
   extraTime:"Extra Time",
   noteLang:"# Match played in neutral ground",
   totalScore:"Total Score",
   handicapLinePastGame :"Handicap lines may not be available for all past games",
   goalMinutesPastGame:"Goal minutes may not be available for all past games",
   cornersDataPastGame:"Corners data may not be available for all past games",
   RankingBasisHandicap:"Ranking Basis: 1) Full Win, 2) Half Win, 3) Draw, 4) Full Loss (low to high), 5) Half Loss (low to high)",
   RankingBasisHandicapHAD:"Ranking Basis: 1) Win, 2) Draws, 3) Loss (low to high)",
   RankingBasisHiLo: "Ranking Basis: 1) Average",
   RankingBasisFHHiLo: "Ranking Basis: 1) Average",
   RankingBasisOddEven: "Ranking Basis: 1) Odd",
   RankingBasisFirstTeamtoScore: "Ranking Basis: 1) Scored 1st",
   RankingBasisCornersHiLo: "Ranking Basis: 1) Average",
   groupAll: "Group All",
   schedule:"Schedule",
   notFound:"This page is temporarily unavailable. Please try again later.",
   footylogicStatsCenter: "Footylogic Stats Center",
   tournament: "Tournament",
   matchList: "Match List",
   HeadtoHead: "Head to Head",
   recentForm: "Recent Form",
   leagueTable: "League table",
   fixturesResults: "Fixtures/Results",
   topScorer: "Top Scorer",
   standings: "Standings",
   rules: "Rules",
   BMHead2Head: "BMHead2Head",
   playerAnalysis: "Player Analysis",
   playersStatistics: "Players Statistics",
   leagueStatistics: "League Statistics",
   teamStatistics: "Team Statistics",
   footylogicStatistics: "Footylogic Statistics",
   correctScoreTournament: "Excludes neutral ground matches",
   selectCountry:"Select Country",
   selectTournment :"Select Tournament",
   selectMatch:"Select Match",
   averageHilo:"Average"
}
export const staticChe ={
    match:"賽事",
    tournament:"聯賽/盃賽",
    headTOHeadSearch:"對賽搜尋",
    teamSearch:"Team Search",
    SearchByTeamName:"球隊名稱",
    homeForm:"主隊近況",
    awayForm:"客隊近況",
    hadPredictor:"勝負預測",
    bestBet:"投注啟示",
    date:"投注推介",
    mcDate:"日期",
    faq:"FAQ",
    contactUs:"Contact Us",
    responsibleGamblingPolicy:"Responsible Gambling Policy",
    privacy:"Privacy",
    disclaimerUs:"Disclaimer Us",
    securityTips:"Security Tips",
    copyRightText:"Copyright @ 2000-2020 The Hong Kong Jockey Club. All Rights Reserved.",
    rules:"賽制",
    footerText:"《數據中心》服務完全由Bettorlogic提供,所載資料只供參考。Bettorlogic將不對任何人士因使用《數據中心》所蒙受的任何損失或損害負上責任亦不會作出任何賠償。敬請留意使用IE11.0/Chrome/Firefox/Safari瀏覽會有較佳用戶體驗。 本頁使用的球隊排名是根據國際排名(國際賽)及Bettorlogic排名(指定盃賽)",
    headToHead:"對賽往績",
    headToHead8RE:"往績 (近8次對賽結果)",
    playerAnalysis:"球員啟示",
    preview:"賽前分析",
    odds:"賠率",
    mcH2H:"往績",
    basicMode:"進入基本版數據中心",
    advancedMode:"進入進階版數據中心",
    summary:"拆局",
    recentForms:"近況",
    statistics:"季績統計",
    hadHandicap:"主客和及讓球",
    hiLoCorners:"入球大細及角球",
    hiLoHandicap:"HiLo & Handicap",
    mcWinPredictor:"勝負預測",
    last8Encounters:"近8次對賽結果",
    pastEncounters:"Past Encounters",
    season:"Season",
    comp:"Comp",
    competition:"賽事",
    homeTeam:"主隊",
    pos:"排名",
    score:"Score",
    ht:"HT",
    scoreHt:"賽果(半場)",
    awayTeam:"客隊",
    had:"主客和",
    fHAD:"半場主客和",
    hdc:"讓球",
    hHAD:"讓球主客和",
    more:"More",
    home:"主隊",
    away:"客隊",
    draw:"和",
    won:"勝",
    lost:"負",
    homeAwayDraw:"主客和",
    firstHalfHAD:"半場主客和",
    goalsPerGame:"場均入球",
    per:"Per",
    game:"Game",
    handicapHAD:"讓球主客和",
    fullTime:"全場",
    halfTime:"半場",
    haFu:"半全場",
    handicap:"讓球",
    handicaps:"讓球",
    correctScore:"波膽",
    csHafu:"波膽/半全場",
    ttg:"總入球",
    fhHiLo:"半場入球大細",
    hiLo:"入球大細",
    btts:"Both Teams to Score",
    bts:"Both to Score",
    tts:"Teams To Score",
    cornersHiLo:"角球大細",
    goal:"Goal",
    line: "球數",
    ftHiLo: "FT HiLo",
    htHiLo:"HT HiLo",
    cornersPerGame:"場均所得角球",
    cornersForPerGame: "場均所得角球",
    cornersAgainstPerGame: "場均所失角球",
    cornersFor: "Corners For",
    for:"for",
    cornersAgainst: "Corners Against",
    totalGoals:"總入球",
    totalGoalsAnalysis:"Total Goals Analysis",
    total:"Total",
    goalTimes:"入球時間",
    firstToScore:"First To Score",
    corners:"角球大細",
    recentResults:"近8場賽績",
    event:"Event",
    ha:"主/客",
    against:"對手",
    ftScore:"賽果",
    htScore:"HT Score",
    ftResult:"勝負",
    result:"賽果",
    win:"勝",
    loss:"負",
    all:"所有",
    team:"球隊",
    p:"賽事",
    w:"勝",
    wTable:"贏",
    d:"和",
    l:"負",
    highIcon:"大",
    lowIcon:"細",
    h:"主",
    lossIcon:"輸",
    hadWinIcon:"勝",
    hadLossIcon:"負",
    hdcWinIcon:"贏",
    hdcLossIcon:"輸",
    a:"客",
    gf:"得球",
    ga:"失球",
    gd:"球差",
    pts:"積分",
    form:"近況(左為最新)", 
    rank:"排名",
    tournamentRank:"名次",
    draws:"和",
    wins:"勝",
    losses:"Losses",
    fullWin:"全贏",
    halfWin:"贏半",
    halfLoss:"輸半",
    fullLoss:"全輸",
    fullWins:"Full Wins",
    halfWins:"Half Wins",
    halfLosses:"Half Losses",
    fullLosses:"Full Losses",
    ww:"贏贏",
    dw:"和贏",
    lw:"輸贏",
    wd:"贏和",
    dd:"和和",
    ld:"輸和",
    wl:"贏輸",
    wbyl:"勝負",
    fhwbyl:"半場勝負",
    dl:"和輸",
    ll:"輸輸",
    avarage:"平均角球總數",
    plus:'受讓"+"',
    minus: '讓球"-"',
    zero:"0",
    hi:"大",
    lo:"細",
    teamScore:"Team Score",
    odd:"單",
    even:"雙",
    scored1st:"先入球",
    conceded1st:"先失球",
    scoredFirst:"先入球",
    concededFirst:"先失球",
    scoredLast:"Scored Last",
    concededLast:"Conceded Last",
    seasonRankingTrend:"Season Ranking Trend",
    hdcTypeComparison:"讓球比較",
    handicapHADComparison:"讓球主客和比較",
    hadComparison:"主客和比較",
    hafuComparison:"半全場比較",
    ttsOEComparison:"入球單雙比較",
    ffsComparison:"第一隊入球比較",
    correctScoreComparision:"波膽比較",
    totalGoalsComparison:"總入球比較",
    temporarilyNoData:"暫時未有資料提供",
    player:"球員",
    teamPlayer:"姓名",
    started:"正選",
    missed:"缺陣",
    goalsFor:"球隊入球",
    with:"正選",
    wo:"缺陣",
    winPer:"勝 (%)",
    drawPer:"和 (%)",
    losePer:"負 (%)",
    goalsAgainst:"球隊失球",
    league:"聯賽",
    cup:"盃賽",
    international:"國際賽",
    standings:"積分榜",
    fixturesResults:"賽程/賽果",
    topScorer :"神射手",
    players:"球員",
    club:"球會",
    nationality:"國籍",
    goals:"入球",
    hKJCNo:"神射手編號",
    squad:"編號",
    position:"位置",
    age:"年齡",
    appearances:"上陣次數",
    assists:"Assists",
    generate:"Generate",
    type:"賽事",
    country:"國家",
    continent:"Continent",
    h2hTitle:"輸入球隊名稱",
    last8HomeResults:"近8場主場成績",
    last8HomeResultsLFL:"近8場主場成績 (左為最新)",
    last8AwayResultsLFL:"近8場作客成績 (左為最新)",
    latestFromLeft:"Latest from left",
    played:"賽事",
    recentEncounters:"Recent Encounters",
    firstHalfHiLo:"FIRST HALF HiLo",
    last8AwayResults:"近8場作客成績",
    seasonSummary:"賽季統計",
    high:"High",
    low:"Low",
    goalScoringTimeAnalysis:"入球時間",
    Recent8Homeleaguematches:"Recent 8 Home league matches",
    Recent8AwayLeagueMatches:"Recent 8 Away league matches",
    nextMatch:"下仗秩序",
    matchResult:"Match Result",
    past3Seasons:"Past 3 Seasons",
    point:"積分",
    wprc:"勝 (%)",
    both:"Both",
    ft:"FT",
    average:"平均總入球",
    matches:"賽事",
    scoreFirst:"先入球",
    oppositScoreFirst:"先失球",
    leagueTable:"積分榜",
    firstHalfCorrectScore:"半場波膽",
    firstTeamToScore:"第一隊入球",
    cornerHiLo:"Corner HiLo",
    firstHalf:"First Half",
    gpg:"GPG",
    search:"搜尋",
    orselectbelow:"選擇球隊",
    yellowcards:"黃牌",
    redcards:"紅牌",
    subbedon:"後備入替",
    subbedoff:"被換出",
    enterteamname:"輸入球隊名稱",
    jan:"一月",
    feb: "二月",
    mar: "三月",
    apr: "四月",
    may: "五月",
    jun: "六月",
    jul:"七月",
    aug: "八月",
    sep: "九月",
    oct: "十月",
    nov: "十一月",
    dec: "十二月",
    january:'一月',
    february:'二月',
    march:'三月',
    april:'四月',
    may:'五月',
    june:'六月',
    july:'七月',
    august:'八月',
    september:'九月',
    october:'十月',
    november:'十一月',
    december:'十二月',
    sunday:'星期日',
    monday:'星期一',
    tuesday:'星期二',
    wednesday:'星期三',
    thursday:'星期四',
    friday:'星期五',
    saturday:'星期六',
    nogoals:"無入球",
    noupcominggames:'No upcoming games',
    koTime: "KO Time",
    homeWin: "主勝",
    awayWin: "客勝",
    count: "次數",
    stats: "數據",
    oddOREven: "入球單雙",
    cornersComparision: "角球大細比較",
    fixtures: "賽程",
    results: "賽果",
    goalLine: "球數",
    htft: "半全場",
    lastUpdatedOn: "資料更新日期",
    last16: "16強",
    quarterFinals: "8強",
    semiFinals: "4強",
    final: "決賽",
    showAll: "顯示所有資料",
    showLess: "顯示較少資料",
    noPastMeetings: "沒有交手紀錄",
    noPastMeetingsVenue:"沒有交手紀錄",
    firstHalfGpg:"半場場均入球",
    "1st":"一日",	
"2nd":"二日",	
"3rd":"三日",	
"4th":"四日",	
"5th":"五日",	
"6th":"六日",	
"7th":"七日",	
"8th":"八日",	
"9th":"九日",	
"10th":"十日",	
"11th":"十一日",	
"12th":"十二日",
"13th":"十三日",	
"14th":"十四日",	
"15th":"十五日",
"16th":"十六日",	
"17th":"十七日",	
"18th":"十八日",
"19th":"十九日",
"20th":"二十日",
"21st":"二十一日",
"22nd":"二十二日",
"23rd":"二十三日",
"24th":"二十四日",
"25th":"二十五日",
"26th":"二十六日",
"27th":"二十七日",
"28th":"二十八日",
"29th":"二十九日",
"30th":"三十日",
"31st":"三十一日",
scoreFirstAndWin:"先入球 / 勝負",
standingsl:"負",
teams:"球隊",
structure:"版式",
promotion:"升班隊數",
relegation:"降班隊數",
tieBreakRules:"同分排名準則",
penalties:"十二碼",
team1:"第一隊",
team2:"第二隊",
firstLeg:"首回合",
secondLeg:"次回合",
aggregate:"總比數",
group:"組",
winner:"冠軍",
runnerUp:"第2名",
corrections:"修正",
points:"分數",
replay:"重賽",
extraTime:"加時",
noteLang:"# 賽事於中立場舉行",
totalScore:"總比數",
handicapLinePastGame :"部份過往賽事的讓球資料或未能提供",
goalMinutesPastGame : "部份過往賽事的入球資料或未能提供",
cornersDataPastGame:"部份過往賽事的角球大細資料或未能提供",
RankingBasisHandicap:"排名準則: 1) 全贏, 2) 贏半, 3) 和, 4) 全輸 (由少至多), 5) 輸半 (由少至多)",
RankingBasisHandicapHAD: "排名準則: 1) 勝, 2)和, 3) 負 (由少至多)",
RankingBasisHiLo: "排名準則: 1) 場均",
RankingBasisFHHiLo: "排名準則: 1) 場均",
RankingBasisOddEven: "排名準則: 1) 單",
RankingBasisFirstTeamtoScore: "排名準則: 1) 先入球",
RankingBasisCornersHiLo: "排名準則: 1) 平均角球總數",
groupAll:"全部",
schedule:"賽程",
notFound:" 此頁現正進行更新，請稍後再到訪。",
footylogicStatsCenter: "數據中心",
tournament: "聯賽 / 盃賽",
matchList: "賽程表",
HeadtoHead: "對賽往績",
recentForm: "近況",
leagueTable: "積分榜",
fixturesResults: "賽程/賽果",
topScorer: "神射手",
rules: "賽制",
BMHead2Head: "BMHead2Head",
seasonalStats: "季績比較",
playerAnalysis: "球員啟示",
playersStatistics: "球員統計",
leagueStatistics: "季績統計",
teamStatistics: "季績統計",
footylogicStatistics: "Footylogic Statistics",
correctScoreTournament: "不包括中立場賽事",
selectCountry:"請選擇國家",
selectTournment :"請選擇聯賽/盃賽",
selectMatch:"請選擇賽事",
averageHilo: "場均"
}
export const configurations = {
    seasonalStats: [42,34,32,140,52,151,141,143,70,51,96,48,13,49,142,149,119,111,72],
    playerAnalysis:[69,102,45,12,73,904,905,903,44,906,907,47,80,42,74,61,6,7,34,5,8,
        64,91,90,71,118,35,28,86,40,9,39,126,78,65,117,125,100,83,11,106,10,84,155,98,32,
        37,36,140,52,151,127,141,143,148,70,75,51,96,48,99,13,49,142,149,38,128,119,111,147,120,
            132,133,112,81,153,67,82,105,109,85,910,72],

    summary :[69,45,12,80,61,64,91,147,120,132,133,112,81,153,82,105,109,85],
    
    bmStatistics :[42,34,32,140,52,151,141,143,70,51,96,48,13,49,142,149,119,111,72],
    teamPlayer :[69,102,45,12,73,904,905,903,44,906,907,47,80,42,74,61,6,7,34,5,8,
                    64,91,90,71,118,35,28,86,40,9,39,126,78,65,117,125,100,83,11,106,10,84,155,98,32,
                37,36,140,52,151,127,141,143,148,70,75,51,96,48,99,13,49,142,149,38,128,119,111,147,120,
                        132,133,112,81,153,67,82,105,109,85,910,72]
    // [6,5,32,11,51,141,119,126,84,86,52,83,39,78,125,142,38,37,36,118,7,90,71,34
    //     ,106,111,100,67,13,8,99,128,10,96,9,98,148,75,70,127,143,117,48,35,28,140
    //     ,151,49,149,102,69,47,74,64,120,112,132,133,147,12,73,81,153,155,65,40,45
    //     ,105,109,82,68,44,85]
}